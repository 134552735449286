import { createApp } from 'vue'
import App from './App.vue'
import { router, fontawesome } from './lib'

import "bootstrap";
import "@/style/style.scss";
import "@formkit/themes/genesis";
import { plugin, defaultConfig } from '@formkit/vue'

import Toaster from "@meforma/vue-toaster";

const app = createApp(App)
    .use(router)
    .use(fontawesome)
    .use(Toaster)
    .use(plugin, defaultConfig)
    ;

app.mount('#app')
