import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faMagnifyingGlassPlus,
    faMagnifyingGlassMinus,
    faUpload,
    faSave,
    faSpinner
} from "@fortawesome/free-solid-svg-icons";

library.add(faMagnifyingGlassPlus);
library.add(faMagnifyingGlassMinus);
library.add(faUpload);
library.add(faSave);
library.add(faSpinner);

export const fontawesome = {
    install(app) {
        app.component("font-awesome-icon", FontAwesomeIcon);
    }
}
