import axios from 'axios';
import { router } from './router';

export const httpClient = axios.create({
    baseURL: "/",
    timeout: 30000,
    headers: { "X-App": "Koncepta.AnnonsAvd" },
});

try {
    httpClient.__accessToken = JSON.parse(window.localStorage.getItem("AAToken"));
} catch {
    window.localStorage.clear("AAToken");
}

httpClient.setAccessToken = (token, expires) => {
    httpClient.__accessToken = { token, expires };
    window.localStorage.setItem("AAToken", JSON.stringify({ token, expires }));
};

httpClient.clearAccessToken = () => {
    delete httpClient.__accessToken;
    window.localStorage.clear("AAToken");
};

httpClient.isAuthenticated = () => {
    if (!httpClient.__accessToken) {
        return false;
    }

    return httpClient.__accessToken.expires - Date.now() > 0;
}

httpClient.interceptors.request.use(
    async (config) => {
        try {
            if (httpClient.__accessToken) {
                config.headers.Authorization = `Token ${httpClient.__accessToken.token}`;
            }
        } catch (err) {
            console.log("*** Unable to aquire token ***");
            console.log(err);
            delete config.headers.Authorization;
        }

        return config;
    },
    (error) => {
        Promise.reject(error);
    });

httpClient.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (axios.isAxiosError(error)) {
            console.log("*** Axios network request error ***");
            const axiosError = error;
            if (axiosError.response?.status === 401) {
                router.push("/login");
            }
        } else {
            console.log("*** Unknown network request error ***");
            console.log(error);
        }

        throw error;
    });
