import { createRouter, createWebHistory } from 'vue-router'
import { httpClient } from "@/lib";

const routes = [
    { path: '/', alias: "/home", component: () => import("@/views/HomeView.vue"), meta: { requiresAuth: true } },
    { path: '/status', component: () => import("@/views/StatusView.vue") },
    { path: '/login', component: () => import("@/views/LoginView.vue") },
    { path: '/logout', component: () => import("@/views/LogoutView.vue") },
    { path: '/logotyp', component: () => import("@/views/LogotypView.vue"), meta: { requiresAuth: true } },
    { path: '/annons', component: () => import("@/views/AnnonsView.vue"), meta: { requiresAuth: true } },
]

export const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to) => {
    if (to.meta.requiresAuth && !httpClient.isAuthenticated()) {
        return {
            path: '/login',
            query: { redirect: to.fullPath },
        }
    }
});
