
<template>
  <div>
    <div class="fluid-container mb-4">
      <nav class="navbar navbar-expand-lg ml-auto navbar-light bg-light">
        <div class="container-fluid">
          <router-link to="/home" class="navbar-brand"
            >AnnonsAvd.se</router-link
          >
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <router-link to="/annons" class="nav-link">Annons</router-link>
              </li>

              <li class="nav-item">
                <router-link to="/logotyp" class="nav-link"
                  >Logotyp</router-link
                >
              </li>
            </ul>
            <button
              type="button"
              @click.prevent="doLogOut"
              class="btn btn-primary float-end"
              v-if="this.auth"
            >
              Logga ut
            </button>
          </div>
        </div>
      </nav>
    </div>
    <div class="container">
      <router-view />
    </div>
  </div>
</template>

<script>
import { httpClient } from "@/lib";

export default {
  name: "App",
  components: {},
  data() {
    return {
      auth: null,
      loading: false,
    };
  },
  mounted() {
    this.isAuthenticated();
  },
  watch: {
    $route() {
      this.isAuthenticated();
    },
  },
  methods: {
    isAuthenticated() {
      this.auth = httpClient.isAuthenticated();
    },
    async doLogOut() {
      await httpClient.get("/api/logout");
      httpClient.clearAccessToken();
      this.$router.push("/login");
      this.auth = false;
    },
  },
};
</script>

<style lang="scss">
:root {
  --bs-body-bg: #f7f8fa !important;
  --bs-light-rgb: 255, 255, 255 !important;
}

.card {
  border-radius: 10px;
  border: 0;
  margin-bottom: 1.5rem;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
}

.menu {
  background: #fff;
  padding: 10px 0;
  display: flex;
  margin-bottom: 10px;

  .menu-item {
    padding: 0 8px;
  }

  a,
  a:visited {
    color: #072a40;
    text-decoration: none;
  }
}
</style>
